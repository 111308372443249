import React from 'react';

import Layout from '../../components/Layout';
import Seo from '../../components/Seo';
import PageHeading from '../../components/PageHeading';

export default () => (
	<Layout>
		<Seo title="Rate Limiting" />
		<PageHeading title="Rate Limiting" />
		<p>This content is a work in progress.</p>
	</Layout>
);
